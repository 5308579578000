import { useRef, useContext, useState, useEffect } from 'react';
import { Stage, Image, Layer, Rect, Label, Tag, Text } from 'react-konva';
import useImage from 'use-image';
import styles from './ImageAudit.module.css';
import BoundingBox from '../BoundingBox';
import { store } from '../../stores/store';
import { checkDeselect, zoom, startDragging, finishDragging, drag } from './canvasMethods';
import Loading from '../../components/Loading';
import { useRouter } from 'next/router';
import { Button, BUTTON_KIND, BUTTON_SIZE, LabelLarge, styled, tokens } from '@premisedata/portal-design-system';

interface ImageAudit extends Audit {
  hideLabels: boolean;
  hideBoundingBoxes: boolean;
}

const AuditContainer = styled('div', ({ $theme }) => ({
  margin: $theme.sizing.small
}));

const AuditHeader = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: $theme.sizing['xx-small']
}));

const CanvasContainer = styled('div', ({ $theme }) => ({
  background: $theme.colors['neutral-75'],
  borderRadius: tokens['corner-radius']['semi-sharp']
}));

const ImageCanvas = ({ id, url, bboxes, name, hideLabels, hideBoundingBoxes }: ImageAudit) => {
  const router = useRouter();

  const s = useContext(store);
  const initialTooltipState = {
    visible: false,
    x: 0,
    y: 0,
    label: ''
  };
  const [tooltip, setTooltip] = useState(initialTooltipState);

  const [stageState, setStageState] = useState({
    scale: 1,
    x: 0,
    y: 0,
    panX: 0,
    panY: 0
  });
  const [keysState, setKeysState] = useState<KeyState>({
    shift: false,
    ctrl: false
  });

  // Set the initial mock drag state
  const initialDragState = {
    dragging: false,
    originX: 0,
    originY: 0,
    boxX: 0,
    boxY: 0,
    boxW: 0,
    boxH: 0
  } as DragState;

  const [dragState, setDragState] = useState<DragState>(initialDragState);

  const containerEl = useRef<HTMLDivElement>(null);
  const target = containerEl.current;

  if (hideLabels) {
    const unlabeled = s.state.labels.find((l) => l.name === 'Unlabeled') as Label;
    bboxes.map((bb) => {
      bb.fullLabel = unlabeled;
    });
  }

  // Zooming / panning
  useEffect(() => {
    // Handle shift and control mod keys
    const keyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift') {
        setKeysState({ shift: true, ctrl: false });
      } else if (e.key === 'Control') {
        setKeysState({
          shift: false,
          ctrl: true
        });
      } else if (e.key === 'Backspace') {
        setTooltip(initialTooltipState);
      }
    };
    // exit mod key state
    const keyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift' || e.key === 'Control') {
        setKeysState({ shift: false, ctrl: false });
      }
    };

    const contextMenu = (e: MouseEvent) => {
      e.ctrlKey && e.preventDefault();
    };

    // handle mod keys listener
    window.addEventListener('keydown', keyDown);
    window.addEventListener('keyup', keyUp);
    window.addEventListener('contextmenu', contextMenu);
    return () => {
      window.removeEventListener('keydown', keyDown);
      window.removeEventListener('keyup', keyUp);
      window.removeEventListener('contextmenu', contextMenu);
    };
  }, []);

  const [img] = useImage(url);
  let height = 0;

  if (target && img) {
    const ratio = img.naturalHeight / img.naturalWidth;
    height = target.clientWidth * ratio;
    img.width = target.clientWidth;
    img.height = height;
  }

  const { debug } = router.query;

  return (
    <AuditContainer>
      <AuditHeader>
        <LabelLarge id={name}>{name}</LabelLarge>
        {bboxes && s.state.box_manipulation && (
          <Button kind={BUTTON_KIND.secondary} size={BUTTON_SIZE.mini} onClick={() => s.dispatch({ type: 'DELETE_ALL_FOR_AUDIT', id })}>
            Delete all boxes ({bboxes.length})
          </Button>
        )}
      </AuditHeader>
      <CanvasContainer ref={containerEl}>
        {(!target || !img) && <Loading />}

        {target && img && height > 0 && (
          <Stage
            className={`${styles.canvas} ${keysState.shift ? styles.panning : keysState.ctrl ? styles.selecting : ''}`}
            width={target.clientWidth}
            height={height}
            onClick={(e) => checkDeselect(e, s.dispatch)}
            scaleX={stageState.scale}
            scaleY={stageState.scale}
            x={stageState.x - stageState.panX}
            y={stageState.y - stageState.panY}
            onWheel={(e) => zoom(e, keysState, setStageState, stageState)}
            onMouseDown={(e) =>
              // on click start the dragging state
              startDragging(e, setDragState, initialDragState, stageState)
            }
            onMouseUp={() =>
              // on lifting the mouse button stop the dragging state and update the bounding box
              finishDragging(dragState, keysState, s.state, s.dispatch, target, initialDragState, setDragState, id, height, setStageState, stageState)
            }
            onMouseMove={(e) =>
              // handle dragging state as the mouse moves
              drag(e, dragState, setDragState, stageState, keysState, setStageState)
            }
          >
            <store.Provider value={s}>
              <Layer>
                <Image image={img} />
                {!hideBoundingBoxes &&
                  bboxes
                    .filter((bb) => (s.state.visibleLabels.length > 0 ? s.state.visibleLabels.includes(bb.label_id) : true))
                    .map((bb, key) => (
                      <BoundingBox
                        key={key}
                        bb={bb}
                        target={target}
                        height={height}
                        width={target.clientWidth}
                        hideTooltip={() => setTooltip(initialTooltipState)}
                        showTooltip={(payload) => setTooltip({ ...tooltip, ...payload, visible: true })}
                      />
                    ))}
                <Rect
                  stroke="#33d4ff"
                  dash={keysState.ctrl ? [10, 10] : undefined}
                  visible={!keysState.shift && dragState.dragging && (keysState.ctrl || !!s.state.selectedLabel)}
                  x={dragState.boxX}
                  y={dragState.boxY}
                  height={dragState.boxH}
                  width={dragState.boxW}
                  fill={!!s.state.selectedLabel && !keysState.shift && keysState.ctrl ? '#33d4ff' : undefined}
                />
              </Layer>
              <Layer>
                <Label visible={tooltip.visible} fill="white" x={tooltip.x} y={tooltip.y - 30}>
                  <Tag fill="white" cornerRadius={4} />
                  <Text text={tooltip.label} padding={8} fontSize={16} fontStyle="bold" />
                </Label>
              </Layer>
              {debug && (
                <Layer>
                  {bboxes
                    .filter((bb) => bb.height === 0 || bb.width === 0)
                    .map((bb) => {
                      console.log(bb);
                      return <Rect key={`debug-${bb.uuid}-${bb.auditUuid}`} visible={true} x={bb.x} y={bb.x} height={2} width={2} stroke="red" strokeWidth={2} rotation={45} />;
                    })}
                </Layer>
              )}
            </store.Provider>
          </Stage>
        )}
      </CanvasContainer>
    </AuditContainer>
  );
};

export default ImageCanvas;
